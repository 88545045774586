import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import ContactFrom from "../../components/ContactFrom/ContactFrom.jsx";
import {useTranslation} from "react-i18next";

const ContactFromContainer = () => {
    const {t} = useTranslation("global", { keyPrefix: 'contact.contactFromContainer' });
    return (
        <div className="contact-form-section section section-padding-t90-b100">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <SectionTitle
                            headingOption="fz-32"
                            title={t("title")}
                            subTitle={t("subTitle")}
                        />
                        <ContactFrom />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactFromContainer;
