import PropTypes from "prop-types";
import {useEffect, useRef} from "react";
import contactDataEn from '../../data/contactInfo/contactInfo-en.json';
import contactDataEs from '../../data/contactInfo/contactInfo-es.json';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import ContactInfoItem from '../../components/ContactInfo/ContactInfoItem.jsx';
import Parallax from 'parallax-js';
import {useTranslation} from "react-i18next";


const ContactInformation = ({ classOption }) => {
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    const [t, i18n] = useTranslation("global", { keyPrefix: 'contact.contactInformation' });
    let lng = i18n.language;

    return (
        <div className={`section section-padding-t90-b100 ${classOption}`}>
            <div className="container shape-animate">
                <SectionTitle
                    titleOption="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8"
                    title={t("title")}
                    subTitle={t("subTitle")}
                />

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 justify-content-around justify-content-lg-center m-1">
                    {
                        lng === 'en' ?
                        contactDataEn && contactDataEn.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up">
                                <ContactInfoItem data={single} key={key} />
                            </div>
                            ); 
                        })
                        :
                        contactDataEs && contactDataEs.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up">
                                <ContactInfoItem data={single} key={key} />
                            </div>
                            ); 
                        })
                    }
                </div>

                <div className="shape shape-1" id="scene" ref={sceneEl}>
                    <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="shape" /></span>
                </div>

            </div>
        </div>
    )
}

ContactInformation.propTypes = {
    classOption: PropTypes.string
};
ContactInformation.defaultProps = {
    classOption: "section section-padding-t90-b100"
};

export default ContactInformation
