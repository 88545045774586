import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";


const CallToActionTwo = () => {
    const {t} = useTranslation("global", { keyPrefix: 'home.callToActionTwo' });
    return (
        <div className="cta-section section section-padding-250">
     
            <div className="container text-center icon-up-down-animation">
                <SectionTitle
                    headingOption="fz-34"
                    title={t("title")}
                    subTitle={t("subTitle")}
                />
                <Link
                    className="btn btn-primary"
                    to={process.env.PUBLIC_URL + "/contact"}
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    {t("button")}
                </Link>

                <div className="shape shape-1">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-1.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-2.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-3.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-4.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-5.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-6.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-7.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-8.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
                <div className="shape shape-9">
                    <span>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon-animation/icon-9.svg"
                            }
                            alt=""
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CallToActionTwo;
