import React from 'react';
import { useForm } from "react-hook-form";
import {useTranslation} from "react-i18next";

const ContactFrom = () => {
    const { register, errors } = useForm({
        mode: "onBlur"
    });
    const {t} = useTranslation("global", { keyPrefix: 'contact.contactFrom' });
    
    return (
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            <form action="https://getform.io/f/df7c49e4-4f31-43cb-9ff4-a557379888cb" method="POST">
                <div className="row mb-n6 ">
                    <div className="col-md-6 col-12 mb-6 contact-form-input">
                        <input type="text" placeholder={t("name")} name="name" ref={register({required: t("refName") })} />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6 contact-form-input">
                        <input type="email" placeholder={t("email")} name="email" ref={register({
                            required: t("refEmail"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address"
                            }
                        })} />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6 contact-form-input">
                        <input type="text" placeholder={t("subjet")} name="subject" ref={register({ required: t("refSubjet") })} />
                        {errors.subject && <p>{errors.subject.message}</p>}
                    </div>
                    <div className="col-12 mb-6 contact-form-input">
                        <textarea name="message" placeholder={t("message")} ref={register({ required: t("refMessage") })}></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" className="btn btn-primary btn-hover-secondary">{t("button")}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactFrom;
