import { useState } from 'react';
import './ToggleLanguage.css';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function ToggleLanguage ({selected, setSelected}) {
    const [isActive, setIsActive] = useState(false);
    const options =['en', 'es'];


      //Translator I18next
      const [t,  i18n] = useTranslation("global", { keyPrefix: 'home.header' });
      const changeLanguage = (lng) => {
          i18n.changeLanguage(lng);
          console.log(i18n)
      };

    return(
        <div className="sp_dropdown">
            <div className="btn btn-light sp_dropdown-btn" onClick={()=> {setIsActive(!isActive)}}>
             {selected === "en" ? t("translator.english") : t("translator.spanish")}
            <i className="fa-sharp fa-solid fa-caret-down"></i>
            </div>

            {isActive && 
                <div className="sp_dropdown-content">
                {options.map((option, index) => (
                    <div 
                        className="sp_dropdown-item" 
                        onClick={()=> {
                            setSelected(option);
                            setIsActive(false);
                            changeLanguage(option);
                            }
                        }
                        key={index}
                    >
                    {option === "en" ?  t("translator.english") : t("translator.spanish")}
                    
                    </div> 
                ))}        
                </div>
            }
        </div>
    )
}
ToggleLanguage.propTypes = {
    selected: PropTypes.string,
    setSelected: PropTypes.func
};


export default ToggleLanguage;