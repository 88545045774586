import React, {useState} from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import QuestionnaireSlider from "../../components/QuiestionnarieSlider/QuiestionnaireSlider";
import {useTranslation} from "react-i18next";

const QuestionnaireSection = () => {
    // ---------- Estados ----------

    // Estado para mostrar el formulario cueando este true.
    const [showForm, setShowForm] = useState(false);

    // Traduccion
    const {t} = useTranslation("global", { keyPrefix: 'home.QuestionarieSection' });

    //---------Funciones----------

    // Setear el estado en true para mostrar el formulario.
    const startForm = () =>{ setShowForm(!showForm)}

    return (
        // Estilos que tienen todas las secciones
        <div className="contact-form-section section section-padding-t90-b100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="d-flex flex-column justify-content-center align-items-center">

                        {/* Seccion del Titulo */}
                        <SectionTitle
                            headingOption="fz-32"
                            title={t("title")}
                            subTitle={t("subTitle")}
                        />

                        {/* Si el boton fue clikeado mostramos el slide, sino el boton de emepzar */}
                        {!showForm && <button onClick={startForm} className="btn btn-primary m-10" data-aos="fade-up" data-aos-delay="300">{t("button")}</button>}

                        <QuestionnaireSlider  show={showForm}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireSection;
