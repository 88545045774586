import React, { useEffect }  from 'react'
import {NavLink, useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next"

const NavBar = () => {
    const { pathname, hash, key } = useLocation();
    const {t} = useTranslation("global", { keyPrefix: 'home.navBar' });

  

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
    
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">{t("home")}</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/#about"}><span className="menu-text">{t("aboutUs")}</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/#services"}><span className="menu-text">{t("services")}</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}



export default NavBar
