import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import PropTypes from "prop-types";



const MobileNavMenu = ({onClose}) => {
    // Translator I18next
    const {t} = useTranslation("global", { keyPrefix: 'home' });

    return (
        <nav className="site-mobile-menu">
            <ul>
                <li onClick={onClose}>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">{t("navBar.home")}</span></NavLink>
                    
                </li>
                <li onClick={onClose}>
                    <NavLink to={process.env.PUBLIC_URL + "/#about"}><span className="menu-text">{t("navBar.aboutUs")}</span></NavLink>
                </li>
                <li onClick={onClose}>
                    <NavLink to={process.env.PUBLIC_URL + "/#services"}><span className="menu-text">{t("navBar.services")}</span></NavLink>
                </li>
                
                <li onClick={onClose}>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">{t("header.contactUs")}</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

MobileNavMenu.propTypes = {
    selected: PropTypes.string,
    setSelected: PropTypes.func,
    onClose: PropTypes.func
};



export default MobileNavMenu;
