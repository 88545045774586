import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import IconBoxDataEn from "../../data/iconBox/icon-box-en.json";
import IconBoxDataEs from "../../data/iconBox/icon-box-es.json";
import IconBox from "../../components/IconBox/IconBox.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from "parallax-js";
import {useTranslation} from "react-i18next"

const ServiceIconBoxTwo = ({ classOption }) => {
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);


    const [t, i18n] = useTranslation("global", { keyPrefix: 'home.serviceIconBoxTwo' });
    let lng = i18n.language;
    return (
        <div
            className={`section section-padding-t90 section-padding-bottom ${classOption}`}
            id="services"
        >
            <div className="container">
                <SectionTitle
                    headingOption="fz-32"
                    title= {t("title")}
                    subTitle= {t("subTitle")}
                />

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-box-shape-animation">
                    {
                        lng === "en" ?
                        IconBoxDataEn && IconBoxDataEn.map((single, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col col-md-12 col-sm-12 mb-6"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <IconBox
                                            classOption="box-border"
                                            data={single}
                                            key={key}
                                        />
                                    </div>
                                );
                            })
                            :
                            IconBoxDataEs && IconBoxDataEs.map((single, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col col-md-12 col-sm-12 mb-6"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <IconBox
                                            classOption="box-border"
                                            data={single}
                                            key={key}
                                        />
                                    </div>
                                );
                            })
                        }

                    <div className="shape shape-1" id="scene" ref={sceneEl}>
                        <span data-depth="1">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/shape-animation/video-shape-1.png"
                                }
                                alt=""
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceIconBoxTwo.propTypes = {
    classOption: PropTypes.string,
};
ServiceIconBoxTwo.defaultProps = {
    classOption: "section section-padding-t90 section-padding-bottom",
};

export default ServiceIconBoxTwo;
