import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import IntroTwo from "../container/IntroSlider/IntroTwo";
import IntroThree from "../container/IntroSlider/IntroThree";
import HomeAbout from "../components/About/HomeAbout.jsx";
import ServiceIconBoxTwo from "../container/service/ServiceIconBoxTwo";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import QuestionnaireSection from "../container/QuestionnaireSection/QuestionnaireSection";

const HomeOne = () => {
    return (
        <React.Fragment>
            <SEO title="Blinkit – Enabling Innovation" />
            <Header />
            <IntroThree />
            <IntroTwo />
            <HomeAbout />
            <ServiceIconBoxTwo />
            <QuestionnaireSection/>    
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    );
};

export default HomeOne;
