import React from "react";
import YoutubeBackground from "react-youtube-background";
import Typed from "react-typed";
import {useTranslation} from "react-i18next"

const IntroTwo = () => {
    const {t} = useTranslation("global", { keyPrefix: 'home.introTwo' });
    

    return (
        <YoutubeBackground
            videoId="UyCKKLFFSXc"
            overlay="rgba(0,0,0,.4)"
            className="intro-section section bg-video d-none d-lg-flex"
        >
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">
                    <div className="col align-self-center">
                        <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                            <h2 className="title ah-headline">
                                {t("title")} <br />{" "}
                                <Typed
                                    strings={[
                                        t("title-animation.first"),
                                        t("title-animation.second"),
                                        t("title-animation.third"),
                                    ]}
                                    typeSpeed={65}
                                    backSpeed={78}
                                    loop
                                />{" "}
                            </h2>
                            <div className="desc">
                                <p>
                                    {t("text")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </YoutubeBackground>
    );
};

export default IntroTwo;
