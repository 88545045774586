import {useState, useEffect, useRef} from "react";
import SectionTitle from '../SectionTitles/SectionTitle';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import {useTranslation} from "react-i18next"

const HomeAbout = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    // Translator
    const {t} = useTranslation("global", { keyPrefix: 'home.homeAbout' });
    return (
        <div className="section section-padding-t90 section-padding-bottom-200" id="about">
            <div className="container">

                <SectionTitle
                    title={t("sectionTitle.title")}
                    subTitle={t("sectionTitle.subTitle")}
                />

                <div className="row">

                    
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "images/about/prueba-1.jpg"} alt="" width={400} height={250} /> */}
                                    <div className="about-img-test" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/about/prueba-1.jpg"})`}}></div>
                                    <div className="overlay-effect"></div>
                                </Tilt>
                            </div>
                        
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    {/* <img src={process.env.PUBLIC_URL + "images/about/prueba-2.jpg"} alt="" width={400} height={250}/> */}
                                    <div className="about-img-test" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/about/prueba-2.jpg"})`}}></div>
                                    <div className="overlay-effect"></div>
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle={t("sectionTitleTwo.subTitle")}
                                title={t("sectionTitleTwo.title")}
                            />

                            <p>{t("sectionTitleTwo.paragraph")}</p>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HomeAbout;
