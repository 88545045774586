import React from "react";
import PropTypes from "prop-types";
import ReactVivus from 'react-vivus';
import '../QuiestionnarieSlider/QuiestionnaireSlider.css';
import {useTranslation} from "react-i18next";
// TODO: Este componente tendra que recibir una proprs en relacion al estado de hacer un axios.post y recibir una respuesta del servidor para mostrar mensjaes succes o erros. Debe funcionar mejor la transicion y buscar por ahi un mejor estilo.

const Notification = ({show}) => {
    const {t} = useTranslation("global", { keyPrefix: 'comopnets.notifications' });
    const classes = ` ${ !show  ? 'notShow'  : 'showw'} p-5 d-flex justify-content-center align-items-center`; 
    return(
        <div className={classes}>
            <h2 className="font-sm">{t("title")}</h2>
            <div className="icon">
                <ReactVivus
                    id="foo"
                    option={{
                        file: "images/svg/linea/linea-arrows-check.svg",
                        animTimingFunction: 'EASE',
                            type: 'oneByOne',
                            delay: 80
                    }}
                    //style={{ height: '100px', width: '100px' }}
                />
            </div>
        </div>
    )
}

Notification.propTypes = {
    show: PropTypes.bool
};
export  default Notification;