import React from "react";
const Footer = () => {

    // Get current year
    let newDate = new Date();
    let currentYear = newDate.getFullYear();
    
    return (
        <div className="section footer-bg-color">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p className="copyright">
                            &copy; {currentYear} <strong>Blinkit</strong> All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
