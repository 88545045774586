// Importo la funcionalidad
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importo los JSON con los textos traducidos
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';

// Inicializo la funcionalidad y la configuro
i18n
.use(initReactI18next)
.init({
  interpolation: {escapeValue:false},
  // Lenguaje por defecto
  fallbackLng: 'en',
  // Va a empezar con el idioma ingles
  lng: "en",
  // Paso los archivos de texto
  resources: {
    es: {
      global: global_es
      
    },
    en: {
      global: global_en
    }
  }
})


export default i18n;