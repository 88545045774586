import React from "react";
import Typed from "react-typed";
import {useTranslation} from "react-i18next";

const IntroThree = () => {

    const {t} = useTranslation("global", { keyPrefix: 'home.introTwo' });
    return (
        <div
            className="intro-section section overlay .d-lg-none"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-image/intento.jpg)`,
            }}
        >
            <div className="container ">
                <div className="row row-cols-lg-1 row-cols-1">
                    <div className="col align-self-center">
                        <div className="intro-content-two headline-active text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                            <h2 className="title ah-headline">
                            {t("title")}{" "}
                                <Typed
                                     strings={[
                                        t("title-animation.first"),
                                        t("title-animation.second"),
                                        t("title-animation.third"),
                                    ]}
                                    typeSpeed={100}
                                    backSpeed={110}
                                    loop
                                />{" "}
                            </h2>
                            <div className="desc">
                                <p>
                                {t("text")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroThree;
