import React, {useState, useEffect, useRef} from "react";
import { Swiper, SwiperSlide} from "swiper/react";
//JSON with content
// Spanish
import QuestionnarieJSONes from "../../data/QuestionnaireJSON/questionnarie-es.json";
// Englsih
import QuestionnarieJSONen from "../../data/QuestionnaireJSON/questionnarie-en.json";
//Translation
import { useTranslation } from "react-i18next";
// Styles
import './QuiestionnaireSlider.css';
// install Swiper modules
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
// import Swiper core and required modules
import SwiperCore, { Pagination,Navigation} from 'swiper/core';
// Others imports
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "react-hook-form";
import Notification from "../FormNotification/Notification";

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);

const QuestionnaireSlider = ({show}) => {
//---------------- Estados ----------------

  //Estado para poder darle funcionalidad de desplazamiento a los botnes prev y next 
  const [swiper, setSwiper] = useState ("");
  // Estado para que determina si esta haciendo el cuestionario (se inicializa en true)
  const [showFinishButton, setShowFinishButton] = useState(false);
  //Estado para pasar al ultimo formulario
  const [quizFinished, setQuizFinished] = useState (false);
  // Estado para la animacion de paso entre secciones
  const [showNotification, setShowNotification] = useState(false);
  // Estado para loading
  const [isLoading, setIsLoading] = useState(false)
  const containerRef = useRef(null);
  // Estado para almacenar toda la data del usuario
  const [dataForm, setDataForm] = useState({
    fullName: '', 
    email: '',
    company: '',
    quiz: []
  });
  // Estado para caputrar el ancho del la pagina
  const [width, setWidth] = useState(window.innerWidth);

  //---------------- Form  Hook ----------------

  const { register, errors } = useForm({
      mode: "onBlur"
  });
 

 //---------------- Translation ----------------
  const [t, i18n]= useTranslation("global", { keyPrefix: 'home.QuestionarieSection' });
  let lng = i18n.language;
//---------------- Funciones ----------------

  // Funcion que va a guardar las respuestas del usuario.
  const handleClick = (answer, question) => {
    // Creo una constante que almacene como objeto la respuesta del usuario
    const currentValue = {question: question, answer: answer}
    // Creo la constante index que va a retornar el numero de indice si esta el objeto o -1 si no lo tiene
    const index = dataForm.quiz.findIndex(ans => ans.question === question)

    // En caso de que el objeto exista, creo una constante donde guardo mi array, actualizo el cambio de respuesta y vuelvo a guardar el estado nuevo.
    if(index !== -1){
      const curretnAnswers = [... dataForm.quiz];
      curretnAnswers[index] = currentValue;
      setDataForm(prevState => ({
        ...prevState,
        quiz: curretnAnswers
        
      }));
    }else{
      // En caso que no exista, simplemente actualizo mi estado con mi respuesta nueva.
      setDataForm(prevState => ({
        ...prevState,
        quiz: [... prevState.quiz, currentValue]
        
      }));
    }
  };
  // Funcion para desplazar a la siguiente slides 
  const nexTo = (evt) =>{
    evt.preventDefault();
      console.log()
      swiper.slideNext();
 
  }
  // Funcion para desplazar a la anterior slides 
  const prevTo = (evt) =>{
    evt.preventDefault();
    swiper.slidePrev();

  }
  // Funcion para que el ususairo avise que termino su quiz y pasar al ultimo form
  const isFinishedQuiz = () => {
    console.log(dataForm.quiz)
    setQuizFinished(true);
   
    
  }
  // Funcion que captura el valor del input
  const handleInputChange = (evt) => {
    setDataForm(prevState => ({
      ...prevState,
      [evt.target.name]:[evt.target.value]
      
    }));
  }
  // Funcion que pregunta si estamos en la ultima slide
  const handleChange = () => {
    setShowFinishButton(swiper.isEnd);
  }
  // Funcition que envía la info a la url
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(!isLoading)
    // Creamos un nuevo objeto fomrData
    const newDataForm = new FormData();
    //Le pasamos la info de los inputs
    newDataForm.append('nombre completo', JSON.stringify(dataForm.fullName.toString()));
    newDataForm.append('email', JSON.stringify(dataForm.email.toString()));
    newDataForm.append('empresa', JSON.stringify(dataForm.company.toString()));
    // Recorremos el estado y le pasamos esa info mas ordenada para que llegue al mail
    dataForm.quiz.forEach(element => {
      newDataForm.append( element.question, JSON.stringify(element.answer.toString()));
    });

    // Envio de la informacion con FETCH
    // TODO: Implementar AXIOS
    fetch("https://getform.io/f/df7c49e4-4f31-43cb-9ff4-a557379888cb",
    {
        method: "POST",
        body: newDataForm,
    })
    .then(response => console.log('respuesta', response))
    .catch(error => console.log('respuesta error',error))
    .finally(()=>{
      setIsLoading(!isLoading);
      setShowNotification(!showNotification)
    })

    

    //TODO: Estado para mostrar msj useState onsend
  };

//----------------Use Effects----------------

// Use efect usado para mandar info y preguntar si es la ultima slide
useEffect(() => {
  if (swiper) {
    swiper.on('slideChange', handleChange);
  }

  return () => {
    if (swiper) {
      swiper.off('slideChange', handleChange);
    }
  };
}, [swiper]);

useEffect(() => {
  function handleResize() {
    setWidth(window.innerWidth);
  }
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

// Use effect usado para resetear el cuestionario cuando se cambia de idioma
useEffect(() => {
  setDataForm(prevState => {
    return { ...prevState, quiz: [] }; // resetea el array quiz a un array vacío
  });
}, [i18n.language]);

//---------------- Classes - CSS logic ----------------

  // Prop que viene del componente padre para mostrar el slider form
  const classes = show ?  'show'  : 'notShow'; 

  const widthPage = width < 1200 ? `flex-row justify-content-start align-items-center text-left` : `flex-column justify-content-center align-items-center  text-center`;
  const widthInput = width < 1200 ? `w-15 mr-md-4` : "";
  const widthText = width < 1200 ? `w-85` : "";



//---------------- Functions to return HTML elements ----------------

//---------- Slides ----------
  const slider = lng === "en" ?   
      QuestionnarieJSONen && QuestionnarieJSONen.map((ask) => {
      // A cada objeto ASK le creo un ID unico
      ask.key = uuidv4();
      return (
        <SwiperSlide key={ask.key}>
        <div className={`sp-container_slide`}>
        {/* Pregunta */}
          <h3 className="text-center title"  id={ask.key}>{ask.quiestion}</h3>
        {/* Contenedo de las tres respuestas */}
          <div className="row container-fluid justify-content-around sp-container_input">
            
              {
                // Dentro del objeto ASK tengo un array con las 3 respuestas, lo recorro y creo los 3 inputs
                ask.options.map(answer => {
                    {/* Le creo una key unica a cada input */}
                    let key = uuidv4();
                    return(
                      <div className={`sp-box_input col-sm-12 col-md-12 col-lg-12 col-xl-3 p-0 m-1 d-flex flex-column justify-content-center ${dataForm.quiz.findIndex(ans => ans.answer === answer.answer) === -1 ?"" : "clicked"}`} key={key} onClick={() => handleClick(answer.answer, ask.quiestion)}
                      >
                        <label htmlFor="answer" className={`desc w-100 h-auto p-3 d-flex ${widthPage}`} > 
                          <input
                            type="radio"
                            name="answer"
                            value={answer.answer}
                            id={key}
                            checked=""
                            onChange={handleChange}
                            className={widthInput}
                            // Lo puse porque me tiraba un warning
                          />
                          <div className={widthText}>
                            {answer.answer}
                          </div>
                          
                        </label>
                      </div>
                    )
                  }
                )
              }
                
          </div>            
        </div>
      </SwiperSlide>
          )//Return
        }//Function
      )//Map;
  :
      QuestionnarieJSONes && QuestionnarieJSONes.map((ask) => {
        // A cada objeto ASK le creo un ID unico
        ask.key = uuidv4();
        return (
          <SwiperSlide key={ask.key}>
          <div className={`sp-container_slide`}>
          {/* Pregunta */}
            <div className="p-2">
              <h4 className="text-center title"  id={ask.key}>{ask.quiestion}</h4>
            </div>
            
          {/* Contenedo de las tres respuestas */}
            <div className="row container-fluid justify-content-around sp-container_input">
              
                {
                  // Dentro del objeto ASK tengo un array con las 3 respuestas, lo recorro y creo los 3 inputs
                  ask.options.map(answer => {
                      {/* Le creo una key unica a cada input */}
                      let key = uuidv4();
                      return(
                        <div className={`sp-box_input col-sm-12 col-md-12 col-lg-12 col-xl-3 p-0 m-1 d-flex flex-column justify-content-center  ${dataForm.quiz.findIndex(ans => ans.answer === answer.answer) === -1 ?"" : "clicked"}`} key={key}
                        >
                          <label htmlFor="answer" className={`desc w-100 h-auto p-3 d-flex ${widthPage}`} onClick={() => handleClick(answer.answer, ask.quiestion)}> 
                            <input
                              type="radio"
                              name="answer"
                              value={answer.answer}
                              id={key}
                              checked=""
                              onChange={handleChange}
                              className={widthInput}
                              // Lo puse porque me tiraba un warning
                            />
                            <div className={widthText}>
                              {answer.answer}
                            </div>
                            
                          </label>
                        </div>
                      )
                    }
                  )
                }
                  
            </div>            
          </div>
        </SwiperSlide>
        )//Return
      }//Function
      );//Map
//---------- Forms HTML ----------
  const renderEndoFor = () => {
    const showForm = quizFinished ? 'show' : 'notShow';
    return (
      <div
        className={`row justify-content-center p-5 mb-n6 fade-in ${showForm}`}
      >
        <div className="col-md-10 col-12 contact-form-input mb-6">
          <h4 className="text-center"> {t("form.succesMessage")}</h4>
        </div>
        <div className="col-md-7 col-12 contact-form-input mb-6">
          <input
            type="text"
            placeholder={t("form.labelName")}
            name="fullName"
            ref={register({ required: t("form.requiredName") })}
            value={dataForm.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <p>{errors.name.message}</p>}
        </div>
        <div className="col-md-7 col-12 contact-form-input mb-6">
          <input
            type="email"
            placeholder={t("form.labelEmail")}
            name="email"
            ref={register({
              required: t("form.requiredEmail"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address",
              },
            })}
            value={dataForm.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <p>{errors.email.message}</p>}
        </div>
        <div className="col-md-7 col-12 contact-form-input mb-6">
          <input
            type="text"
            placeholder={t("form.labelCompany")}
            name="company"
            ref={register({ required: t("form.requiredCompany") })}
            value={dataForm.company}
            onChange={handleInputChange}
            required
          />
          {errors.subject && <p>{errors.subject.message}</p>}
        </div>
        <div className="col-7 d-flex justify-content-center mb-6">
          {isLoading ? (
            <div className="custom-loader"></div>
          ) : (
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary "
            >
              {t("form.btnSubmit")}
            </button>
          )}
        </div>
      </div>
    );
  }
  
//---------------- Render ----------------

  return ( 
      <div className={`${classes} sp-section_slider contact-form w-100`}>
        {!showNotification ?
        <form className={`d-flex flex-column  justify-content-evenly align-items-center w-auto`}   onSubmit={handleSubmit} method="POST" acceptCharset="UTF-8"  ref={containerRef} action="https://getform.io/f/df7c49e4-4f31-43cb-9ff4-a557379888cb">
        
        { 
         //Mostrar el slider siempre que el estado este en falso
         !quizFinished &&
         
           <Swiper
           spaceBetween={50}
           slidesPerView={1} //Se avanza de a 1 slide
           pagination={{"type": "fraction"}} //Conteo de slides
           navigation={false} // Muestra las flechas
           className="mySwiper"
           allowSlidePrev={true}//Bloquea el vovler para atras
           allowSlideNext={true} //-> habilita seguir avanzando con las slides. Crear una funcion que cuando pase algo lo habilite
           allowTouchMove={false}
           onSwiper={(e) => setSwiper(e)}//Agreago para que funcione el desplazamiento del slide cuando se hace click en una opcion
           //onReachEnd={()=> setLastSlide(false)} //Evento que detecta si es la ultima slide
           onSlideChange={() => handleChange()}
           >
             {slider}  
             <div className="d-flex container-fluid justify-content-between w-container">
             <div className="">
              <button type="button" className="btn btn-primary m-3" onClick={prevTo}>{t("slider.btnPrev")}</button>
             </div>
             <div className="">
             {
                 showFinishButton ?
                   <button type="submit" className="btn btn-primary m-3" disabled={!(dataForm.quiz.length === QuestionnarieJSONes.length)} onClick={isFinishedQuiz } >{t("slider.btnFinish")}</button> 
                 : 
                   <button type="button" className="btn btn-primary m-3" onClick={ nexTo } >{t("slider.btnNext")}</button> 
               }
             </div>
              
             </div>
         </Swiper>

        }

        {renderEndoFor()}
         
         
        </form>
       :
       <Notification show={showNotification} /> 
      }
      </div> 
  )
}

QuestionnaireSlider.propTypes = {
  show: PropTypes.bool
};
export default QuestionnaireSlider;