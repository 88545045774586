import React from 'react';
import PropTypes from "prop-types"
import {Link} from "react-router-dom";


const Btn = (props) => {
    return (
        <React.Fragment>
            <Link to={props.linkTo} className="btn btn-primary">{props.name}</Link>
        </React.Fragment>
    )
}

Btn.propTypes = {
    name: PropTypes.string,
    linkTo: PropTypes.string
}

export default Btn
